var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-module"
  }, [_c('portfolio-blind-filters'), _c('div', {
    staticClass: "portfolio-module__container"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('sygni-container-title', [_vm._v("Investment by Company")]), _c('portfolio-blind-table', {
    attrs: {
      "perPage": 10
    }
  })], 1)]), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }