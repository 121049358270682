













import Vue from 'vue'
import Component from 'vue-class-component'
import PortfolioBlindFilters from '../components/PortfolioBlindFilters.vue'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue'
import PortfolioBlindTable from '../components/PortfolioBlindTable.vue'
import SygniAccessModal from '@/components/layout/SygniAccessModal.vue';
import {mapState} from "vuex";

@Component({
  components: { PortfolioBlindFilters, SygniContainerTitle, PortfolioBlindTable, SygniAccessModal },
  computed: mapState('portfolio', {
    portfolioTable: (state: any) => state.portfolioTable,
    isBusy: (state: any) => state.portfolioTableIsBusy,
    perPage: (state: any) => state.portfolioTable.perPage,
  })
})
export default class PortfolioModule extends Vue {
  filtersQueryString: string = '';
  perPageOption?: number = 10;

  get perPageValue() {
    return this.perPageOption;
  }
}
